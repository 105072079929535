var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"queryInfo":_vm.queryInfo,"tool-list":['campus','keyword']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"羽毛球场地","label-width":"90px"}},[_c('el-select',{attrs:{"clearable":"","disabled":!_vm.courtList.length,"size":"small"},model:{value:(_vm.queryInfo.badmintonRoomId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "badmintonRoomId", $$v)},expression:"queryInfo.badmintonRoomId"}},_vm._l((_vm.courtList),function(ref){
var name = ref.name;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":name,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"预约开始日期","label-width":"100px"}},[_c('el-date-picker',{attrs:{"type":"date","size":"small","placeholder":"开始日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.queryInfo.startDate),callback:function ($$v) {_vm.$set(_vm.queryInfo, "startDate", $$v)},expression:"queryInfo.startDate"}})],1),_c('el-form-item',{attrs:{"label":"预约结束日期","label-width":"100px"}},[_c('el-date-picker',{attrs:{"type":"date","size":"small","placeholder":"结束日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.queryInfo.endDate),callback:function ($$v) {_vm.$set(_vm.queryInfo, "endDate", $$v)},expression:"queryInfo.endDate"}})],1),_c('el-form-item',{attrs:{"label":"是否取消"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.cancelFlag),callback:function ($$v) {_vm.$set(_vm.queryInfo, "cancelFlag", $$v)},expression:"queryInfo.cancelFlag"}},[_c('el-option',{attrs:{"value":0,"label":"否"}}),_c('el-option',{attrs:{"value":1,"label":"是"}})],1)],1),_c('el-form-item',{attrs:{"label":"是否核销"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.fixedFlag),callback:function ($$v) {_vm.$set(_vm.queryInfo, "fixedFlag", $$v)},expression:"queryInfo.fixedFlag"}},[_c('el-option',{attrs:{"value":0,"label":"否"}}),_c('el-option',{attrs:{"value":1,"label":"是"}})],1)],1),_c('el-form-item',{attrs:{"label":"用户类型"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.userType),callback:function ($$v) {_vm.$set(_vm.queryInfo, "userType", $$v)},expression:"queryInfo.userType"}},_vm._l((Object.values(_vm.$store.state.systemParam.userTypeMap)),function(ref){
var value = ref.value;
var label = ref.label;
return _c('el-option',{key:value,attrs:{"value":value,"label":label}})}),1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"prop":"day","label":"日期","width":"120"}}),_c('el-table-column',{attrs:{"label":"时间","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$moment(row.startTime).format('HH:mm'))+"~"+_vm._s(_vm.$moment(row.endTime).format('HH:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"badmintonRoomName","label":"羽毛球场地","min-width":"110","align":"center"}}),_c('el-table-column',{attrs:{"prop":"campusName","label":"校区","width":"120"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"预约人姓名","min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.userType)?_c('el-tag',{attrs:{"type":_vm.$store.state.systemParam.userTypeMap[row.userType].type}},[_vm._v(_vm._s(_vm.$store.state.systemParam.userTypeMap[row.userType].label))]):_vm._e(),_vm._v(" "+_vm._s(row.stuName || row.orderTeaName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"预约人学号/教工号","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuNo || row.orderTeaNo)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"预约人电话","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.fixedPhone || row.orderTeaPhone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"核销人","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.fixedUserType)?_c('el-tag',{attrs:{"type":_vm.$store.state.systemParam.userTypeMap[row.fixedUserType].type,"size":"small"}},[_vm._v(_vm._s(_vm.$store.state.systemParam.userTypeMap[row.fixedUserType].label))]):_vm._e(),_vm._v(" "+_vm._s(row.teaName || row.fixedStuName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"teaName","label":"核销状态","width":"90","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.fixedFlag)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已核销")]):(row.cancelFlag)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("已取消")]):_c('el-tag',[_vm._v("待核销")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"170","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!(row.fixedFlag||row.cancelFlag))?[(_vm.isOutOfDate(row))?_c('span',{staticClass:"font-grey"},[_vm._v(" "+_vm._s(_vm.isOutOfDate(row))+" ")]):_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['badminton:order:cancel']),expression:"['badminton:order:cancel']"}],attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.cancel(row)}}},[_vm._v("取消预约 ")])]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }